module.exports = [{
      plugin: require('/buddy/hamilton-exhibits-2-0/node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"9308072","respectDNT":true,"productionOnly":true},
    },{
      plugin: require('/buddy/hamilton-exhibits-2-0/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-53MWFP6","includeInDevelopment":false},
    },{
      plugin: require('/buddy/hamilton-exhibits-2-0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hamilton Exhibits","short_name":"HME","start_url":"/","background_color":"#253444","theme_color":"#253444","display":"minimal-ui","icon":"src/images/icon.png"},
    }]
